import mixpanel from "mixpanel-browser";
import * as amplitude from "@amplitude/analytics-browser";
import { dumpLogs, loggedSession } from "../hooks/dumpLogs";
import { Capacitor } from "@capacitor/core";
import { getStudentFromStorage } from "@helpers/migrateLogin";
import { PAGE_CONTEXT_NAME } from "@utils/constants";
import Analytics, { AnalyticsInstance } from "analytics";
import mixpanelPlugin from "@analytics/mixpanel";
import amplitudePlugin from "@analytics/amplitude";

let mixpanelInstance: any = null; // Store Mixpanel instance at the module level
let amplitudeInstance: any = null;
let clevertapInstance: any = null;

// Dynamically import CleverTap only on client side
let clevertap: any = null;
let isBrowser = typeof window !== "undefined";

const clevertapPlugin = () => {
    const accountId = process.env.NEXT_PUBLIC_CLEVERTAP_ACCOUNT_ID!;
    const region = process.env.NEXT_PUBLIC_CLEVERTAP_REGION! || "eu1";

    if (!accountId) {
        console.error(
            "CleverTap account ID is missing. Please provide a valid account ID.",
        );
        return;
    }

    const clevertapInit = async () => {
        await new Promise(async (resolve, reject) => {
            try {
                // Dynamically import CleverTap on client side
                if (!clevertap) {
                    clevertap = await require("clevertap-web-sdk");
                }

                // Ensure clevertap is initialized properly
                if (!clevertap.init) {
                    throw new Error("CleverTap SDK is not loaded correctly.");
                }

                clevertap.init(accountId);

                // Ensure methods are available before using
                if (clevertap.setOptOut) {
                    clevertap.setOptOut(false);
                } else {
                    console.warn(
                        "CleverTap setOptOut method is not available.",
                    );
                }

                if (clevertap.privacy?.setOptOut) {
                    clevertap.privacy.setOptOut(false);
                }

                clevertap.setLogLevel(1);

                console.log("CleverTap initialized successfully");
                resolve(true);
            } catch (err) {
                console.error("Failed to initialize CleverTap:", err);
                reject(err);
            }
        });
    };

    // Return analytics plugin object
    return {
        name: "clevertap",
        initialize: async () => {
            return;
        },
        // Identify user
        identify: async ({ payload }) => {
            await clevertapInit();
            if (!isBrowser || !clevertap) return; // Skip if not browser or not initialized

            const { userId, traits } = payload;
            if (userId) {
                try {
                    // Format traits for CleverTap (camelCase to PascalCase)
                    const formattedTraits = flattenObject(traits || {});

                    // Log before sending data
                    console.log("Identifying user:", userId);

                    if (clevertap.onUserLogin) {
                        clevertap.onUserLogin.push({
                            Site: {
                                Identity: userId,
                                ...formattedTraits,
                            },
                        });
                        console.log("User identified successfully");
                    } else {
                        console.warn(
                            "CleverTap onUserLogin method is not available.",
                        );
                    }
                } catch (err) {
                    console.error("Error identifying user:", err);
                }
            }
        },
        // Track events
        track: async ({ payload }) => {
            if (!isBrowser || !clevertap) return; // Skip if not browser or not initialized

            const { event, properties } = payload;
            if (event) {
                try {
                    const flattenedProps = flattenObject(properties || {});
                    // Log before sending event data
                    // console.log(`Tracking event: ${event}`, flattenedProps);

                    if (clevertap.event && clevertap.event.push) {
                        clevertap.event.push(event, flattenedProps);
                        // console.log(`Event ${event} tracked successfully`);
                    } else {
                        console.warn(
                            "CleverTap event.push method is not available.",
                        );
                    }
                } catch (err) {
                    console.error("Error tracking event:", err);
                }
            }
        },
        // Page views
        page: async ({ payload }) => {
            if (!isBrowser || !clevertap) return; // Skip if not browser or not initialized

            const { properties } = payload;
            try {
                const flattenedProps = flattenObject(properties || {});
                // Log before sending page view event data
                console.log("Tracking page view:", flattenedProps);

                if (clevertap.event && clevertap.event.push) {
                    clevertap.event.push("Page Viewed", flattenedProps);
                    console.log("Page view tracked successfully");
                } else {
                    console.warn(
                        "CleverTap event.push method is not available.",
                    );
                }
            } catch (err) {
                console.error("Error tracking page view:", err);
            }
        },
        // Reset/logout user
        reset: async () => {
            if (!isBrowser || !clevertap) return; // Skip if not browser or not initialized

            try {
                // CleverTap doesn't have a direct logout method
                // We can push a logout event instead
                if (clevertap.event && clevertap.event.push) {
                    clevertap.event.push("User Logged Out");
                    console.log("User logged out successfully");
                } else {
                    console.warn(
                        "CleverTap event.push method is not available.",
                    );
                }
            } catch (err) {
                console.error("Error logging out user:", err);
            }
        },
    };
};

// Flatten nested objects
const flattenObject = (obj: any, prefix = ""): any => {
    let flattened: any = {};

    for (const key in obj) {
        if (!Object.prototype.hasOwnProperty.call(obj, key)) continue;
        const newKey = prefix ? `${prefix}_${key}` : key;

        if (typeof obj[key] === "object" && obj[key] !== null) {
            // Instead of flattening, store the entire object as a JSON string
            flattened[newKey] = JSON.stringify(obj[key]);
        } else {
            flattened[newKey] = obj[key];
        }
    }

    return flattened;
};

// Helper function to format phone number
const formatPhoneNumber = (phone: string) => {
    // Check if phone number starts with country code, else prepend +91 (assuming India)
    if (!phone.startsWith("+")) {
        return `+91${phone}`;
    }
    return phone;
};

function init() {
    // if (!mixpanelInstance) {
    //     mixpanelInstance.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN!, {
    //         persistence: "localStorage",
    //         ignore_dnt: true,
    //         batch_requests: true,
    //         api_host: process.env.NEXT_PUBLIC_MIXPANEL_PROXY_URL,
    //     });
    // }

    if (!amplitudeInstance) {
        amplitudeInstance = amplitude;
        amplitudeInstance.init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY!, {
            saveEvents: true, // Ensures events are stored if offline
            includeUtm: true, // Tracks UTM parameters
            includeReferrer: true, // Tracks referrer info
            disableCookies: false, // Enables cookies for tracking
            defaultTracking: false,
        });
    }
}

function appendToUserTraits(key: string, value: any) {
    if (/* !mixpanelInstance &&  */ !amplitudeInstance) {
        console.warn(
            "Analytics instances are not initialized. Initializing now...",
        );
        init();
    }

    // Mixpanel append
    // if (mixpanelInstance?.people) {
    //     mixpanelInstance.people.append(key, value);
    // }

    // Amplitude append
    if (amplitudeInstance) {
        const identifyObj = new amplitude.Identify().append(key, value);
        amplitudeInstance.identify(identifyObj);
    }
}

class MixpanelEventClass {
    student = {};
    mathai_platform = "";
    activity_type = "CHALLENGE";
    query_param_keys: any = [];
    query_param_values: any = [];
    user_is_currently_on = PAGE_CONTEXT_NAME.direct;
    referrer_screen = [PAGE_CONTEXT_NAME.direct];
    referrer_screen_info = "";
    analytics: AnalyticsInstance | null = null;
    distinct_id = "";

    init() {
        if (this.analytics) {
            return;
        }

        // Initialize analytics with mixpanel plugin
        this.analytics = Analytics({
            app: "awesome-app",
            plugins: [
                // mixpanelPlugin({
                //     token: process.env.NEXT_PUBLIC_MIXPANEL_TOKEN!,
                //     options: {
                //         persistence: "localStorage",
                //         ignore_dnt: true,
                //         batch_requests: true,
                //         api_host: process.env.NEXT_PUBLIC_MIXPANEL_PROXY_URL,
                //     },
                // }),
                amplitudePlugin({
                    apiKey: process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY!,
                }),
                clevertapPlugin(),
            ],
        });

        this.mathai_platform = Capacitor.getPlatform();

        // Initialize Mixpanel and store it in the module variable
        // mixpanelInstance = mixpanel;
        // mixpanelInstance.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN!, {
        //     persistence: "localStorage",
        //     ignore_dnt: true,
        //     batch_requests: true,
        //     api_host: process.env.NEXT_PUBLIC_MIXPANEL_PROXY_URL,
        // });

        // Initialize Amplitude and store it in the module variable
        amplitudeInstance = amplitude;
        amplitudeInstance.init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY!, {
            saveEvents: true, // Ensures events are stored if offline
            includeUtm: true, // Tracks UTM parameters
            includeReferrer: true, // Tracks referrer info
            disableCookies: false, // Enables cookies for tracking
            defaultTracking: false,
        });

        // Store reference to CleverTap
        clevertapInstance = clevertap;
    }

    identify(userId: string) {
        const student = JSON.parse(getStudentFromStorage() || "{}");
        if (student) {
            const other = JSON.parse(
                typeof student?.other === "string" ? student?.other : `{}`,
            );
            this.student = {
                id: student.id,
                name: student.name,
                mobile: student.mobile,
                grade: student.class_name,
                type: student.type,
                parent_name: other?.parent_name,
                email: student.email,
                school_name: other?.school_name,
            };
        }
        this.analytics?.identify(userId);
        let other = student?.other || {};
        try {
            other = JSON.parse(student?.other);
        } catch {}
        const params = new URLSearchParams(window.location.search);
        const restrictedParams = [
            "worksheet",
            "worksheet_standalone",
            "attempt",
            "lastAttempt",
            "showQuestion",
            "worksheet_id",
            "activity_id",
            "context",
            "chunk_preview",
            "progression_id",
            "progression_activity_id",
            "path_id",
            "variant_id",
            "block_id",
            "logs",
            "session_id",
            "user_id",
            "student_id",
            "view_id",
            "preview_id",
            "snapshot_type",
            "returnTo",
            "noCache",
            "referrer_screen",
        ];
        this.distinct_id = userId;

        params.forEach((value, key) => {
            if (restrictedParams.includes(key)) return;
            MixpanelEvent.addKeyValueArray(key, value);
        });

        this.analytics?.identify(userId, {
            name: student.name,
            email: student.email,
            phone: student.mobile,
            grade: student.class_name,
            type: student.type,
            gender: student.gender,
            access_type: student.access_type,
            parent_name: other?.parent_name,
            maths_feeling: other?.maths_feeling,
            pervious_marks: other?.pervious_marks,
            // language: other?.language,
            competition_prep: other?.competition_prep,
            goals: other?.goals,
            goals_commitment: other?.goals_commitment,
            profile_picture: other?.profile_picture,
            school_name: other?.school_name,
            profile_id: student.id,
            // query_param_keys: [],
            // query_param_values: []
        });
    }
    defaultEvents = { utm_source: "" };
    referrer_screen_params = [
        "worksheet_id",
        "utm_source",
        "activity_id",
        "typ",
        "ref",
        "ch",
    ];
    getReferrerQueryValues(query) {
        const referrerQueryValues: any = {};
        this.referrer_screen_params.forEach((param) => {
            if (query[param]) {
                referrerQueryValues[param] = query[param];
            }
        });
        return Object.keys(referrerQueryValues)
            .map((key) => `${key}:${referrerQueryValues[key]}`)
            .join("__");
    }
    updatePageLocation = (currentScreen, router) => {
        const { asPath, isReady, pathname, query } = router;
        this.referrer_screen_info = this.getReferrerQueryValues(query);
        this.user_is_currently_on = currentScreen;
        // console.trace('this', currentScreen)
        this.referrer_screen.push(currentScreen);
    };
    addEditionRoutes(routes: Array<any>) {
        if (routes.length > 0) {
            this.referrer_screen.push(...routes);
        }
    }
    track(event: string, data?: any) {
        if (event === "screen_change") {
            console.log(
                "screen_change",
                this.user_is_currently_on,
                this.referrer_screen,
            );
        }
        const logged_session_id = loggedSession.logged_session_id;
        this.analytics?.track(
            event,
            {
                ...this.defaultEvents,
                user_is_currently_on: this.user_is_currently_on,
                user_came_from: this.referrer_screen,
                referrer_screen_info: this.referrer_screen_info,
                ...data,
                ...this.student,
                mathai_platform: this.mathai_platform,
                ...(logged_session_id ? { logged_session_id } : {}),
                activity_type: this.activity_type,
            },
            null,
            (...e) => {
                if (e[0] !== 1)
                    dumpLogs({
                        action: "mixpanel_track_fail",
                        ...this.student,
                        event,
                        data,
                    });
            },
        );
    }
    appendToUserTraits(key, value) {
        if (!this.analytics) {
            this.init();
        }

        appendToUserTraits(key, value);
    }

    addKeyValueArray(key, value) {
        // Ensure both key and value are appended correctly
        this.appendToUserTraits("query_param_keys", key);
        this.appendToUserTraits("query_param_values", value);
    }

    getStudent() {
        return {
            distinct_id: this.distinct_id,
            ...this.student,
        };
    }
}
export const MixpanelEvent = new MixpanelEventClass();
